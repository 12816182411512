import React from "react"
import { Link, graphql, PageProps } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import InfiniteScroll from "react-infinite-scroller"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostMeta from "../components/post-meta"

import { IconButton } from "@mui/material"
import ViewListIcon from "@mui/icons-material/ViewList"
import ListIcon from "@mui/icons-material/List"
import ViewModuleIcon from "@mui/icons-material/ViewModule"

import { IndexPageQuery } from "../../graphql-types"

import "../styles/index-page.scss"

type PostNode = IndexPageQuery["allMdx"]["edges"][number]["node"]

const Article: React.FC<{ post: PostNode }> = ({ post }) => {
  const slug = post.fields!.slug!
  const title = post.frontmatter!.title || slug
  const fixedImg = !!post.frontmatter!.thumbnail
    ? post.frontmatter!.thumbnail!.childImageSharp?.gatsbyImageData
    : ""

  return (
    <article key={slug} className="post article">
      <Link to={slug} className="thumbnail link">
        <GatsbyImage image={fixedImg} alt="thumbnail" />
      </Link>
      <div className="content">
        <div>
          <h3 className="title">
            <Link to={slug} className="link">
              {title}
            </Link>
          </h3>
          <div className="meta">
            <PostMeta post={post} />
          </div>
        </div>
        <section>
          <p
            dangerouslySetInnerHTML={{
              __html: post.excerpt!,
            }}
          />
        </section>
      </div>
    </article>
  )
}

type PostEdges = IndexPageQuery["allMdx"]["edges"]

type Props = {
  posts: PostEdges
  showPosts: number
  className?: string
}

const Posts: React.FC<Props> = ({ posts, showPosts, className }) => {
  const postsCompornent = posts.slice(0, showPosts).map(({ node }) => {
    return <Article post={node} />
  })
  return <div className={className}>{postsCompornent}</div>
}

const BlogIndex: React.FC<PageProps<IndexPageQuery>> = ({ data, location }) => {
  //const posts = data.allMarkdownRemark.edges
  const posts = data.allMdx.edges

  // TODO: どこで管理するのがいいか
  const pageSize = 10
  const [hasMore, setHasMore] = React.useState(true)
  const [pageNum, setPageNum] = React.useState(1)

  let loadMore = () => {
    if (pageSize * pageNum >= posts.length) {
      setHasMore(false)
      return
    }
    setPageNum(pageNum + 1)
  }

  type Layout = "list" | "simple" | "card"

  const [layout, setLayout] = React.useState("simple")
  const SelectPostsLayoutButton: React.FC = () => {
    const selected = (s: Layout): string => (layout === s ? "selected" : "") // icon highlight
    return (
      <div className="select-layout">
        <IconButton
          onClick={() => {
            setLayout("list")
          }}
          className={selected("list")}
        >
          <ViewListIcon width="24px" height="24px" />
        </IconButton>
        <IconButton
          onClick={() => {
            setLayout("simple")
          }}
          className={selected("simple")}
        >
          <ListIcon width="24px" height="24px" />
        </IconButton>
        <IconButton
          onClick={() => {
            setLayout("card")
          }}
          className={selected("card")}
        >
          <ViewModuleIcon width="24px" height="24px" />
        </IconButton>
      </div>
    )
  }

  const loader = <div className="loader">Loading...</div>
  const content = (
    <>
      <SEO title="all posts" />
      <SelectPostsLayoutButton />
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        hasMore={layout === "simple" ? false : hasMore}
        loader={loader}
      >
        <Posts
          posts={posts}
          showPosts={layout === "simple" ? posts.length : pageNum * pageSize}
          className={layout}
        />
      </InfiniteScroll>
    </>
  )
  return <Layout location={location}>{content}</Layout>
}

export default BlogIndex

export const pageQuery = graphql`
  query IndexPage {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { draft: { eq: false } } }
    ) {
      edges {
        node {
          excerpt # (format: MARKDOWN)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY/MM/DD")
            title
            tags
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  width: 150
                  height: 150
                  placeholder: BLURRED
                  layout: FIXED
                )
              }
            }
          }
        }
      }
    }
  }
`

//...GatsbyImageSharpFixed
